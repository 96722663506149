/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:29:45 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 14:29:45 
 */
<template>
  <div class="see">
    <el-dialog
      title="查询结果"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="see_cont">
        <el-row>
          <el-col :span="3">已选内容：</el-col>
          <el-col :span="21">{{results.Title}}</el-col>
        </el-row>
        <el-row>
          <el-col :span="3">我的作答：</el-col>
          <el-col :span="21">
            <div v-html="results.StudentContent" class="comment"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">教师回复：</el-col>
          <el-col :span="21">
            <div v-html="results.Comment" class="comment"></div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { studentQueryStudentWorkDetail } from "@/api/personal";
export default {
  props: ["dialogVisible","row"],
  data() {
    return {
      results:{}
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    async init(){
      let res = await studentQueryStudentWorkDetail(this.row.Id);
      if (res.success === true) {
        this.results = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.see {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .see_cont{
      line-height: 40px;
      .el-row{
        margin-bottom: 10px;
        font-size: 14px;
        .comment{
         img{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>