/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 18:03:32 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-18 11:54:41
 */
<template>
  <div class="myTask">
    <h3 class="personal_title">
      <el-popover
        placement="bottom"
        title=""
        width="200"
        trigger="hover"
        content=""
      >
        <el-scrollbar style="height: 210px">
          <div
            class="courseList"
            v-for="item in courseList"
            :key="item.CourseId"
            @click="handleChange(item)"
          >
            {{ item.CourseName }}
          </div>
        </el-scrollbar>
        <div slot="reference" class="courseList_hover">{{ selectVal }}</div>
      </el-popover>
    </h3>
    <!-- <div class="personal_top">
      <span
        v-for="(lt, i) in list_top"
        :key="i"
        :class="listTopIndex == lt.id ? 'active' : ''"
        @click="listTopClick(lt)"
        >{{ lt.name }}</span
      >
    </div> -->
    <!-- :header-cell-style="{ background: '#F3F3F3', color: '#666666' }" -->
    <div class="cont">
      <el-table border :data="tableData">
        <el-table-column
          align="left"
          prop="Title"
          label="作业名称"
        ></el-table-column>
        <el-table-column
          align="left"
          prop="CourseName"
          label="所属课程"
        ></el-table-column>
        <el-table-column align="center" label="发布时间" width="100">
          <template slot-scope="scope">
            {{ scope.row.CreateTime | dataYMD }}
          </template></el-table-column
        >
        <el-table-column align="center" label="截止时间" width="100">
          <template slot-scope="scope">
            {{ scope.row.FinishTime | dataYMD }}
          </template></el-table-column
        >
        <el-table-column align="center" label="作答状态" width="100">
          <template slot-scope="scope">
            {{
              scope.row.StudentWorkStatus == 0
                ? "未批阅"
                : scope.row.StudentWorkStatus == 1
                ? "已批阅"
                : "未提交"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-button
                size="mini"
                v-if="listTopIndex == 1"
                class="bgc_1288F4"
                @click="handleSee(scope.row)"
                >查看</el-button
              >

              <!-- <el-button
                size="mini"
                v-if="listTopIndex == 1 && scope.row.StudentWorkStatus!=1"
                class="bgc_FE846B"
                @click="handleUpload(scope.row)"
                >上传</el-button
              > -->

              <!-- <el-button
               v-if="scope.row.StudentWorkStatus != null"
                size="mini"
                class="bgc_7079E7"
                @click="handleResults(scope.row)"
                >结果</el-button
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 查看 -->
    <see
      v-if="seeDialogVisible"
      :dialogVisible="seeDialogVisible"
      :row="seeRow"
      @close="getSeeClose()"
    ></see>
    <!-- 上传 -->
    <upload
      v-if="uploadDialogVisible"
      :dialogVisible="uploadDialogVisible"
      :row="seeRow"
      @close="getUploadClose()"
    ></upload>
    <!-- 查询结果 -->
    <results
      v-if="resultsDialogVisible"
      :dialogVisible="resultsDialogVisible"
      :row="seeRow"
      @close="getResultsClose()"
    ></results>
  </div>
</template>

<script>
import { studentQueryCourseWorkList, questionBankQueryQuestionCourse } from "@/api/personal";
import see from "./components/see";
import upload from "./components/upload";
import results from "./components/results";
export default {
  data () {
    return {
      list_top: [
        { name: "待完成", id: 1 },
        { name: "已提交", id: 2 },
        { name: "已完成", id: 3 },
      ],
      tableData: [],
      listTopIndex: 1,
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
      seeDialogVisible: false,
      uploadDialogVisible: false,
      resultsDialogVisible: false,
      seeRow: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      selectVal: "",
      CourseId: "",
      courseList: [],
    };
  },
  components: {
    see,
    upload,
    results,
  },
  created () { },
  mounted () {
    this.getQuestionBankQueryQuestionCourse();
  },
  methods: {
    // 查询结果 弹窗关闭
    getResultsClose () {
      this.resultsDialogVisible = false;
    },
    //查询结果
    handleResults (row) {
      this.seeRow = row;
      this.resultsDialogVisible = true;
    },
    //下载
    handleDownload () { },
    // 上传 弹窗关闭
    getUploadClose () {
      this.uploadDialogVisible = false;
    },
    //上传
    handleUpload (row) {
      this.seeRow = row;
      this.uploadDialogVisible = true;
    },
    // 查看 弹窗关闭
    getSeeClose () {
      this.seeDialogVisible = false;
    },
    //查看
    handleSee (row) {
      this.seeRow = row;
      this.seeDialogVisible = true;
    },
    //分页
    handleSizeChange (val) {
      this.page = val;
      init();
    },
    handleCurrentChange (val) {
      this.page = val;
      this.init();
    },
    listTopClick (obj) {
      this.listTopIndex = obj.id;
      this.page = 1;
    },
    handleChange (obj) {
      this.selectVal = obj.CourseName;
      this.CourseId = obj.CourseId;
      this.tableData = []
      this.page = 1
      this.init()
    },
    async getQuestionBankQueryQuestionCourse () {
      let parm = {
        studentId: this.userInfo.Id,
      };
      const res = await questionBankQueryQuestionCourse(parm);
      if (res.success === true) {
        this.courseList = res.response;
        this.CourseId = this.courseList[0].CourseId;
        this.selectVal = this.courseList[0].CourseName;
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    async init () {
      let parm = {
        courseId: this.CourseId,
        studentId: this.userInfo.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await studentQueryCourseWorkList(parm);
      if (res.success === true) {
        this.tableData = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}

.courseList {
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
.myTask {
  .personal_title {
    height: auto;
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
  }
  .courseList_hover {
    width: 217px;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    padding: 0 10px;
    border: 1px solid #dcdfe6;
    color: #606266;
    font-size: 14px;
  }
  .cont {
    padding: 30px;
    .el-button {
      color: #ffffff;
      border: none !important;
      &:hover {
        background: inheriti;
      }
    }
  }
}
</style>